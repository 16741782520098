<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Supply Chain Management (SCM)</h2>
      <p class="desc my-3">
        The concept of Cloud computing is causing a modification regarding how
        manufacturing as well as supply chain administrations utilize the IT
        services, thus offering time-to-time resolutions in order to address
        state-of-the-art business tasks.
      </p>
      <p class="desc my-3">
        Renovate your supply chain from practical requirements to a competitive
        benefit.
      </p>
      <p class="desc my-3">
        In order to upgrade all of your operations over the supply, demand and
        product standards. A wide and in-depth variety of skills are offered by
        Oracle SCM solutions.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/supply-chain.png"
        alt="Supply Chain Management (SCM)"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-oracle-section",
};
</script>

<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Oracle Sales Cloud</h2>
      <p class="desc my-3">
        With the help of Oracle Sales Cloud comprehensive, ground-breaking and
        verified sales solution, you can undertake clients beforehand to speed
        up and secure more deals.
      </p>
      <p class="desc my-3">
        Your client has changed and your trade requirements are changing
        constantly. Fast and user-friendly tools, cellphone throughput,
        cooperative and thorough selling along with destructive production of a
        pipeline are required by modern sales in order to improve sales and
        enhance productivity. More is required and needed by you right now.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/oracle-sales.png"
        alt="Oracle Sales Cloud"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-oracle-section",
};
</script>

<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Project Portfolio Management Cloud</h2>
      <p class="desc my-3">
        Authorize and guide the team followers to make the optimum assets on
        each assignment, be informed of project performance fashions/trends, and
        cooperate efficiently from commencement to delivery.
      </p>
      <p class="desc my-3">
        Enterprise and several organization clients are allowed by PPM to
        establish a sequence of projects into their relevant portfolios and
        deliver reports on the basis of several project resources, objectives,
        risks, costs, as well as other relevant associations. The executive
        or/and management users of an organization are allowed by Project
        Management Cloud software to assess the portfolio that will contribute
        to making the main business and financial pronouncements for projects.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/supply-chain1.png"
        alt="Project Portfolio Management Cloud"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-oracle-section",
};
</script>

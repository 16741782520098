<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Oracle Financials Cloud</h2>
      <p class="desc my-3">
        Provide business managers with efficient procedures, offer enhanced
        throughput to the finance club and provide clients with the desired
        approach to role-based information.
      </p>
      <p class="desc my-3">
        Maximize and simplify the economic user practice, while making it
        simpler in real-time to manage as well as to examine your business. In
        the process of decision making, finance associations are anticipated to
        be a vital strategic player. Thus, for making healthier business
        decisions, it demands access to supplementary information all over the
        corporation, better analytical insight along with real-time reporting.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/financle-cloud.png"
        alt="Oracle Financials Cloud"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-oracle-section",
};
</script>

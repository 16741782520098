<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Oracle Procurement Cloud</h2>
      <p class="desc my-3">
        Discover and explore more savings prospects, modernize buying
        procedures, diminish costs, and impose agreement compliances.
      </p>
      <p class="desc my-3">
        Use Procurement Cloud in order to modernize your source-to-pay procedure
        with the help of automation as well as societal partnership, while
        monitoring costs and reaching greater boundaries.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/oracle-procurement-cloud.png"
        alt="Oracle Procurement Cloud"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-oracle-section",
};
</script>

<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Oracle RightNow</h2>
      <p class="desc my-3">
        A reputation for providing outstanding client service can distinguish
        your drive revenue and brand. You can accomplish this reputation with
        the help of Oracle by converting your online self-care into a rather
        highly branded and cooperative client experience. Oracle RightNow Web
        Self Service Cloud Service thrusts appropriate information to where your
        clients want it, thus exploiting the exterior Web 2.0 knowledge
        resources required by them in order to make purchasing decisions.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/oracle--1.png"
        alt="Oracle RightNow"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eightth-oracle-section",
};
</script>

<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Enterprise Resource Planning (ERP)</h2>
      <p class="desc my-3">
        You can authorize your teams, help them grow wiser and take full
        advantage of your IT investment with the help of Oracle’s proven cloud
        solutions. Based upon proven practices, Oracle ERP Cloud offers elastic
        cloud solutions that help in supporting standardized and automated
        global business procedures. Improved activity, insightful
        decision-making process, enhanced efficiency, minimal costs, and greater
        capability to innovate are some of the benefits of this, all of which
        allow you to drive the success of your business.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/oracle-fusion-erpimg-8.png"
        alt="Enterprise Resource Planning (ERP)"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-oracle-section",
};
</script>

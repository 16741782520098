<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">
        Oracle Human Capital Management Cloud
      </h2>
      <p class="desc my-3">
        Modern HR allows individuals to take advantage of user-friendly tools
        that are customized, societal, unique, bring intuitions and secure the
        entire lifespan of an employee. You can permit collaboration, search and
        store the best unique talents, give comprehensive details of the staff,
        improve working throughput and allow each individual to connect on any
        sort of device all with the help of Oracle HCM Cloud.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/software-management/oracle-fusion/human-capital-management.png"
        alt="Oracle Human Capital Management Cloud"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-oracle-section",
};
</script>

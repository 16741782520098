<template>
  <oracle-fusion-erp />
</template>

<script>
import OracleFusionErp from "../../components/solutions/software-management/OracleFusionErp.vue";
export default {
  name: "oracle-fusion-erp-page",
  components: { OracleFusionErp },
};
</script>
